import { createRouter, createWebHistory } from 'vue-router'
import { decodeJwt } from 'jose'
import store from '@/store'

const Leningen = () => import('@/views/portal/Leningen.ts.vue')
const Leningverstrekker = () => import('@/views/portal/Leningverstrekker.ts.vue')
const Certificaathouder = () => import('@/views/portal/Certificaathouder.ts.vue')
const Messages = () => import('@/views/portal/Messages.ts.vue')
const Documents = () => import('@/views/portal/Documents.ts.vue')
const DocumentViewer = () => import('@/views/portal/DocumentViewer.ts.vue')
const Landing = () => import('@/views/Landing.ts.vue')
const Portal = () => import('@/views/portal/Portal.ts.vue')
const Login = () => import('@/views/Login.ts.vue')
const FAQs = () => import('@/views/FAQs.ts.vue')
const Registration = () => import('@/views/Register.ts.vue')
const PasswordReset = () => import('@/views/PasswordReset.ts.vue')
const IdentityValidation = () => import('@/views/portal/IdentityValidation.ts.vue')
const LidContractant = () => import('@/views/portal/LidContractant.ts.vue')
const Facturen = () => import('@/views/portal/Facturen.ts.vue')
const Contact = () => import('@/views/Contact.ts.vue')
const NotFound = () => import('@/views/404.ts.vue')

const router = createRouter({
	history: createWebHistory(),
	routes: 
	
	import.meta.env.VITE_APP_ == 'true' ?
	[
		{
			path: '/',
			name: 'Landing',
			component: Landing,
			meta: {
				title: 'Home',
				headerButtons: [
					{
						title: 'Archief',
						icon: 'DocumentDuplicateIcon',
						link: '/documenten'
					},
					{
						title: 'Persoonlijk portaal',
						icon: 'UserIcon',
						link: '/portaal'
					}
				]
			}
		},
		{
			path: '/portaal',
			name: 'Portaal',
			component: Portal,
			meta: {
				title: 'Portaal',
				subpage: true
			},
			children: []
		},
		{
			path: '/berichten/:id',
			name: 'Berichten',
			component: Messages,
			meta: {
				title: 'Berichten',
				subpage: true
			},
			children: []
		},
		{
			path: '/documenten',
			name: 'Documenten',
			component: Documents,
			meta: {
				title: 'Documenten',
				subpage: true
			},
			children: []
		},
		{
			path: '/documenten/:id',
			name: 'document-viewer',
			component: DocumentViewer,
			meta: {
				title: 'Documenten',
				subpage: true
			},
			children: []
		},
		{
			path: '/portaal/leningverstrekker',
			name: 'Leningverstrekker',
			component: Leningverstrekker,
			meta: {
				title: 'Leningverstrekker',
				subpage: true
			}
		},
		{
			path: '/portaal/leningen',
			name: 'Leningen',
			component: Leningen,
			meta: {
				title: 'Leningen',
				subpage: true
			}
		},
		{
			path: '/portaal/certificaathouder',
			name: 'Certificaathouder',
			component: Certificaathouder,
			meta: {
				title: 'Certificaathouder',
				subpage: true
			}
		},
		{
			path: '/portaal/identity-validation',
			name: 'IdentityValidation',
			component: IdentityValidation,
			meta: {
				title: 'Identiteitsvalidatie',
				subpage: true
			}
		},
		{
			path: '/portaal/lid-contractant',
			name: 'LidContractant',
			component: LidContractant,
			meta: {
				title: 'Lid Contractant',
				subpage: true
			}
		},
		{
			path: '/portaal/facturen',
			name: 'Facturen',
			component: Facturen,
			meta: {
				title: 'Facturen & Documenten',
				subpage: true
			}
		},
		{
			path: '/:catchAll(.*)',
			name: 'not-found',
			component: NotFound,
			meta: {
				title: '404'
			}
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
			meta: {
				title: 'Log in'
			}
		},
		{
			path: '/portaal/contact',
			name: 'Contact',
			component: Contact,
			meta: {
				title: 'Contact',
				subpage: true
			}
		},
		{
			path: '/portaal/meest-gestelde-vragen',
			name: 'MeestGeseldeVragen',
			component: FAQs,
			meta: {
				title: 'Meest Gestelde Vragen',
				subpage: true
			}
		},
		{
			path: '/account-registration',
			name: 'Registration',
			component: Registration,
			meta: {
				title: 'Accountregistratie'
			}
		},
		{
			path: '/password-reset',
			name: 'PasswordReset',
			component: PasswordReset,
			meta: {
				title: 'Wachtwoord Reset'
			}
		}
	]
	:
	[
		{
			path: '/',
			name: 'Landing',
			component: Portal,
			meta: {
				title: 'Huis'
			}
		},
		{
			path: '/portaal',
			name: 'Portaal',
			component: Portal,
			meta: {
				title: 'Huis'
			}
		},
		{
			path: '/portaal/leningverstrekker',
			name: 'Leningverstrekker',
			component: Leningverstrekker,
			meta: {
				title: 'Leningverstrekker',
				subpage: true
			}
		},
		{
			path: '/portaal/certificaathouder',
			name: 'Certificaathouder',
			component: Certificaathouder,
			meta: {
				title: 'Certificaathouder',
				subpage: true
			}
		},
		{
			path: '/portaal/identity-validation',
			name: 'IdentityValidation',
			component: IdentityValidation,
			meta: {
				title: 'Identiteitsvalidatie',
				subpage: true
			}
		},
		{
			path: '/portaal/lid-contractant',
			name: 'LidContractant',
			component: LidContractant,
			meta: {
				title: 'Lid Contractant',
				subpage: true
			}
		},
		{
			path: '/portaal/facturen',
			name: 'Facturen',
			component: Facturen,
			meta: {
				title: 'Facturen & Documenten',
				subpage: true
			}
		},
		{
			path: '/:catchAll(.*)',
			name: 'not-found',
			component: NotFound,
			meta: {
				title: '404'
			}
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
			meta: {
				title: 'Log in'
			}
		},
		{
			path: '/contact',
			name: 'Contact',
			component: Contact,
			meta: {
				title: 'Contact',
				subpage: true
			}
		},
		{
			path: '/meest-gestelde-vragen',
			name: 'MeestGeseldeVragen',
			component: FAQs,
			meta: {
				title: 'Meest Gestelde Vragen',
				subpage: true
			}
		},
		{
			path: '/account-registration',
			name: 'Registration',
			component: Registration,
			meta: {
				title: 'Accountregistratie'
			}
		},
		{
			path: '/password-reset',
			name: 'PasswordReset',
			component: PasswordReset,
			meta: {
				title: 'Wachtwoord Reset'
			}
		}
	]
})

router.beforeEach(async (to, from, next) => {
	if (import.meta.env.VITE_APP_ != 'true' && to.path == '/') {
		next({ name: 'Portaal' })
	}

	const validated = false
	document.title = to.meta.title + ' | SwifterwinT'
	if (to.name === 'Registration' && to.query.userKey) {
		if (!store.getters.isLoggedIn) {
			store.commit('setAccountRegistrationKey', to.query.userKey)
			next()
		} else {
			next({ name: 'Landing' })
		}
	} else if (to.name === 'PasswordReset' && to.query.token) {
		if (!store.getters.isLoggedIn) {
			store.commit('setPasswordResetKey', to.query.token)
			next()
		} else {
			next({ name: 'Landing' })
		}
	} else {
		if (to.name !== 'Login' && !store.getters.isLoggedIn) {
			next({ name: 'Login' })
		} else if (to.name == 'Login' && store.getters.isLoggedIn) {
			next({ name: 'Landing' })
		} else {
			let navigated = false
			if (store.getters.accessToken) {
				const jwtObject = decodeJwt(store.getters.accessToken)
				if (jwtObject) {
					const d = new Date()
					if (jwtObject.expires && Number.parseInt(jwtObject.expires.toString()) < d.getTime()) {
						next({ name: 'Landing' })
						navigated = true
						store.commit('setCurrentUser', {})
					}
				}
			}
			if (!navigated) {
				next()
			}
		}
	}
})

export default router
