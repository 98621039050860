/* eslint no-console: 1 */
import Axios from 'axios'
import { decodeJwt } from 'jose'
import store from '@/store'

const baseService = {
	baseURL() {
		return `${import.meta.env.VITE_APP_API_URL}`
	},
	formURL(url: string) {
		return this.baseURL() + url
	},
	generateConfigData(): any {
		if (store.getters.getCurrentUser) {
			const target_copy: any = Object.assign({}, store.getters.getCurrentUser)
			return {
				baseURL: this.baseURL(),
				timeout: 100000,
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${target_copy.accessToken}`
				}
			}
		} else {
			return {
				baseURL: this.baseURL(),
				timeout: 100000,
				headers: {
					'content-type': 'application/json',
					authorization: ''
				}
			}
		}
	},

	async processRequest(endpoint: string, data?: any) {
		try {
			const payload = decodeJwt(store.getters.getCurrentUser.token)
			const d = new Date()
			if (Number(d) > Number(payload.exp)) {
				/// redirect and sign user out.
			}
			return await Axios.post(
				baseService.formURL(endpoint),
				data,
				baseService.generateConfigData()
			).catch(async function (error) {
				if (error.response) {
					if (error.response.status === 401) {
						return await Axios.post(
							baseService.formURL(endpoint),
							data,
							baseService.generateConfigData()
						)
					}
				}
			})
		} catch (error: any) {
			if (error.toString().includes('401')) {
				//await baseService.refreshAccessToken()
				//return await Axios.post(baseService.formURL(endpoint), data, baseService.generateConfigData())
			}
		}
	}
}

export default baseService
