<template>
	<div>
		<TransitionRoot as="template" :show="showPrompt">
			<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" :open="showPrompt">
				<div
					class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="ease-in duration-200"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
						>&#8203;</span
					>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
						>
							<div>
								<div
									class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-sky-100"
								>
									<PencilSquareIcon class="h-6 w-6 text-primary" aria-hidden="true" />
								</div>
								<div class="mt-3 text-center sm:mt-5">
									<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
										Gebruikers gegevens wijzigingen
									</DialogTitle>
									<DialogDescription as="h4" class="text-gray-700">
										{{ user.name }} <br />
										({{ selectedCategory.id === 1 ? user.phoneNo : user.email }})
									</DialogDescription>
									<div class="mt-2">
										<PulseLoader
											v-if="loading"
											color="#009ee0"
											class="mt-8 mb-8 text-center w-full"
										></PulseLoader>
										<div v-show="!loading && !success" class="max-w-3xl mx-auto">
											<div class="bg-white">
												<div
													class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
												>
													<label
														for="about"
														class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
													>
														Details te wijzigen
														<span class="text-red-600">*</span>
													</label>
													<div class="mt-1 sm:mt-0 sm:col-span-2">
														<select
															v-model="selectedCategory"
															:class="[
																categoryError ? 'border-red-600' : 'border-gray-300',
																'rounded-md shadow-sm w-full focus:ring-primary'
															]"
														>
															<option v-for="item in items" :key="item.id" :value="item">
																{{ item.val }}
															</option>
														</select>
													</div>
												</div>
												<form class="space-y-8 divide-y divide-gray-200">
													<div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
														<div>
															<div class="sm:mt-5 space-y-6 sm:space-y-5">
																<div
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="about"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Nieuw <br />{{ selectedCategory.description }}
																		<span class="text-red-600">*</span>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<input
																			:value="tel1Value"
																			type="tel"
																			:class="[
																				tel1Error ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
																			]"
																			@change="tel1Value_Changed"
																		/>
																	</div>
																</div>

																<div
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Bevestig {{ selectedCategory.description }}
																		<span class="text-red-600">*</span>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<input
																			:value="tel2Value"
																			type="tel"
																			:class="[
																				tel2Error ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
																			]"
																			@change="tel2Value_Changed"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div
											v-if="success"
											class="mx-auto mt-6 flex items-center justify-center h-12 w-12 rounded-full"
										>
											<CheckIcon class="h-8 w-8 text-primary" aria-hidden="true" />
										</div>
										<p v-if="success" class="mt-3">
											SwifterwinT is op de hoogte van uw nieuwe gegevens en zal contact met u
											opnemen om te bevestigen wanneer het is geupdate.
										</p>
									</div>
								</div>
							</div>
							<div class="text-center text-red-600 text-sm mt-3 mx-3">
								{{ errorMsg }}
							</div>
							<div v-if="!loading && !success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border shadow-sm px-4 py-2 mr-2 bg-white text-base font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="close()"
								>
									Annuleren
								</button>
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 ml-2 bg-primary text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="save()"
								>
									Doorgaan
								</button>
							</div>
							<div v-if="success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 ml-2 bg-primary text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="close()"
								>
									Sluit
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
		<ErrorDialog ref="errorPrompt" />
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import ErrorDialog from './ErrorDialog.vue'
import {
	Dialog,
	DialogOverlay,
	DialogTitle,
	DialogDescription,
	TransitionChild,
	TransitionRoot
} from '@headlessui/vue'
import { PencilSquareIcon, CheckIcon } from '@heroicons/vue/24/outline'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import datastormsService from '@/services/datastormsService'

export default defineComponent({
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		DialogDescription,
		TransitionChild,
		TransitionRoot,
		PencilSquareIcon,
		ErrorDialog,
		PulseLoader,
		CheckIcon
	},
	props: {
		showPrompt: {
			type: Boolean,
			default: false
		}
	},
	emits: ['close'],
	setup() {
		const errorPrompt: any = ref(null)

		const { t } = useI18n({
			inheritLocale: true,
			useScope: 'global'
		})

		return {
			errorPrompt,
			t
		}
	},
	data() {
		return {
			selectedCategory: {
				id: 1,
				val: 'Telefoonnummer',
				description: 'nummer'
			},
			loading: false,
			success: false,
			categoryError: false,

			tel1Value: '',
			tel2Value: '',

			tel1Error: false,
			tel2Error: false,

			errorMsg: '',

			items: {
				1: { id: 1, val: 'Telefoonnummer', description: 'nummer' },
				2: { id: 2, val: 'Emailadres', description: 'email' }
			}
		}
	},
	computed: {
		...mapGetters({
			user: 'getCurrentUser'
		})
	},
	methods: {
		showDialog() {
			this.tel1Value = ''
			this.tel2Value = ''
			this.loading = false
		},
		tel1Value_Changed(e: any) {
			this.tel1Value = e.target.value
		},
		tel2Value_Changed(e: any) {
			this.tel2Value = e.target.value
		},
		save() {
			this.tel1Error = false
			this.tel2Error = false

			if (!this.tel1Value) {
				this.tel1Error = true
			}
			if (!this.tel2Value || this.tel2Value != this.tel1Value) {
				this.tel2Error = true
			}

			if (!this.tel1Error && !this.tel2Error) {
				this.loading = true

				const data =
					this.selectedCategory.id === 1
						? datastormsService.updatePhoneNumber(this.user.id, this.tel1Value)
						: datastormsService.updateEmail(this.user.id, this.tel1Value)
				var parent = this
				Promise.resolve(data).then(function (value: any) {
					parent.loading = false
					if (value.status === 200 && value) {
						parent.showDialog()
						parent.tel1Value = ''
						parent.tel2Value = ''
						parent.tel1Error = false
						parent.tel2Error = false
						parent.selectedCategory = parent.items['1']
						parent.success = true
					} else {
						parent.errorPrompt.showDialog()
					}
				}, null)
			}
		},
		close() {
			this.selectedCategory = this.items['1']
			this.success = false
			this.tel1Value = ''
			this.tel2Value = ''
			this.tel1Error = false
			this.tel2Error = false
			this.$emit('close')
		}
	}
})
</script>
