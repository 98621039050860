<template>
	<div>
		<TransitionRoot as="template" :show="showPrompt">
			<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" :open="showPrompt">
				<div
					class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="ease-in duration-200"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
						>&#8203;</span
					>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
						>
							<div>
								<div
									class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-sky-100"
								>
									<PencilSquareIcon class="h-6 w-6 text-primary" aria-hidden="true" />
								</div>
								<div class="mt-3 text-center sm:mt-5">
									<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
										Wachtwoord veranderen
									</DialogTitle>
									<DialogDescription as="h4" class="text-gray-700">
										{{ user.name }}
									</DialogDescription>
									<div class="mt-2">
										<PulseLoader
											v-if="loading"
											color="#009ee0"
											class="mt-8 mb-8 text-center w-full"
										></PulseLoader>
										<div v-show="!loading && !success" class="max-w-3xl mx-auto">
											<div class="bg-white">
												<form class="space-y-8 divide-y divide-gray-200">
													<div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
														<div>
															<div class="sm:mt-5 space-y-6 sm:space-y-5">
																<div
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="about"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Huidige Wachtwoord
																		<span class="text-red-600">*</span>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<input
																			:value="currentPassword"
																			type="password"
																			:class="[
																				currentPasswordError ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
																			]"
																			@change="currentPassword_Changed"
																		/>
																	</div>
																</div>

																<div
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="about"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Nieuw Wachtwoord
																		<span class="text-red-600">*</span>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<input
																			:value="newPassword1"
																			type="password"
																			:class="[
																				newPassword1Error ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
																			]"
																			@keyup="newPassword1_changed"
																			@change="newPassword1_changed"
																		/>
																	</div>
																	<div class="Password__strength-meter2 col-span-3">
																		<div :class="passwordClass"></div>
																	</div>
																</div>

																<div
																	class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
																>
																	<label
																		for="about"
																		class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
																	>
																		Bevestig Wachtwoord
																		<span class="text-red-600">*</span>
																	</label>
																	<div class="mt-1 sm:mt-0 sm:col-span-2">
																		<input
																			:value="newPassword2"
																			type="password"
																			:class="[
																				newPassword2Error ? 'border-red-600' : 'border-gray-300',
																				'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
																			]"
																			@change="newPassword2_changed"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
										<div
											v-if="success"
											class="mx-auto mt-6 flex items-center justify-center h-12 w-12 rounded-full"
										>
											<CheckIcon class="h-8 w-8 text-sky-600" aria-hidden="true" />
										</div>
										<p v-if="success">U wachtwoord is succesvol bijgewerkt</p>
									</div>
								</div>
							</div>
							<div class="text-center text-red-600 text-sm mt-3 mx-3">
								{{ errorMsg }}
							</div>
							<div v-if="success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 ml-2 bg-primary text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="close()"
								>
									Sluit
								</button>
							</div>
							<div v-if="!loading && !success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border shadow-sm px-4 py-2 mr-2 bg-white text-base font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:text-sm"
									@click="close"
								>
									Annuleren
								</button>
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 ml-2 bg-primary text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="save()"
								>
									Doorgaan
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
		<ErrorDialog ref="errorPrompt" />
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import ErrorDialog from './ErrorDialog.vue'
import {
	Dialog,
	DialogOverlay,
	DialogTitle,
	DialogDescription,
	TransitionChild,
	TransitionRoot
} from '@headlessui/vue'
import { PencilSquareIcon, CheckIcon } from '@heroicons/vue/24/outline'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import datastormsService from '@/services/datastormsService'
import zxcvbn from 'zxcvbn'

export default defineComponent({
	components: {
		// eslint-disable-next-line vue/no-reserved-component-names
		Dialog,
		DialogOverlay,
		DialogTitle,
		DialogDescription,
		TransitionChild,
		TransitionRoot,
		PencilSquareIcon,
		ErrorDialog,
		PulseLoader,
		CheckIcon
	},
	props: {
		showPrompt: {
			type: Boolean,
			default: false
		}
	},
	emits: ['close'],
	setup() {
		const errorPrompt: any = ref(null)

		const { t } = useI18n({
			inheritLocale: true,
			useScope: 'global'
		})
		return {
			errorPrompt,
			t
		}
	},
	data() {
		return {
			loading: false,
			success: false,

			currentPasswordError: false,
			newPassword1Error: false,
			newPassword2Error: false,

			currentPassword: '',
			newPassword1: '',
			newPassword2: '',

			errorMsg: ''
		}
	},
	computed: {
		...mapGetters({
			user: 'getCurrentUser'
		}),
		passwordStrength() {
			return this.newPassword1 ? zxcvbn(this.newPassword1).score : null
		},
		isSecure() {
			return this.newPassword1 ? this.newPassword1.length >= 7 : null
		},
		passwordClass() {
			var css = ''
			if (this.newPassword1) {
				switch (this.passwordStrength) {
					case 0:
						css = 'h-1 bg-red-500 w-20'
						break
					case 1:
						css = 'h-1 bg-orange w-40'
						break
					case 2:
						css = 'h-1 bg-yellow-200 w-60'
						break
					case 3:
						css = 'h-1 bg-sky-400 w-80'
						break
					case 4:
						css = 'h-1 bg-sky-600 w-100'
						break
				}
			}
			return css
		}
	},
	methods: {
		currentPassword_Changed(e: any) {
			this.currentPassword = e.target.value
		},
		newPassword1_changed(e: any) {
			this.newPassword1 = e.target.value
		},
		newPassword2_changed(e: any) {
			this.newPassword2 = e.target.value
		},
		save() {
			this.errorMsg = ''
			this.currentPasswordError = false
			this.newPassword1Error = false
			this.newPassword2Error = false

			if (!this.currentPassword) {
				this.currentPasswordError = true
			}
			if (!this.newPassword1) {
				this.newPassword1Error = true
			}
			if (!this.newPassword2 || this.newPassword1 != this.newPassword2) {
				this.newPassword2Error = true
			}
			if (!this.isSecure) {
				this.newPassword1Error = true
				this.errorMsg = 'Wachtwoord is niet sterk genoeg (minimaal 7 cijfers)'
			}
			if (!this.currentPasswordError && !this.newPassword1Error && !this.newPassword2Error) {
				this.loading = true

				const data = datastormsService.updateUserPassword(
					this.user.id,
					this.currentPassword,
					this.newPassword1
				)
				var parent = this
				Promise.resolve(data)
					.then(function (value: any) {
						parent.loading = false
						var state = value.status
						if (value) {
							if (state && state.toString() == '200') {
								parent.currentPassword = ''
								parent.newPassword1 = ''
								parent.newPassword2 = ''
								parent.success = true
							} else if (value.data === '' || (value.data.code && value.data.code === 'REL514')) {
								parent.errorMsg = 'Huidige wachtwoord is onjuist'
							} else {
								parent.errorPrompt.showDialog()
							}
						} else {
							parent.errorPrompt.showDialog()
						}
					}, null)
					.catch(err => {
						parent.loading = false
						if (err.status === 400 && err && err.code === 'REL514') {
							parent.errorMsg = 'Huidige wachtwoord is onjuist'
						} else {
							parent.errorPrompt.showDialog()
						}
					})
			}
		},
		close() {
			this.loading = false
			this.success = false

			this.currentPasswordError = false
			this.newPassword1Error = false
			this.newPassword2Error = false

			this.currentPassword = ''
			this.newPassword1 = ''
			this.newPassword2 = ''

			this.errorMsg = ''
			this.success = false
			this.$emit('close')
		}
	}
})
</script>
<style>
.Password__strength-meter2 {
	height: inherit;
	background: transparent;
	border-color: #fff;
	border-style: solid;
	border-width: 0 5px;
	width: 100%;
	z-index: 10;
	position: relative;
	height: 3px;
	background: #ddd;
	border-radius: 3px;
}
</style>
