/* eslint no-console: 1 */
import Axios from 'axios'
import baseService from '@/services/baseService'

const userService = {
	async authenticateLogin(pUsername: string, pPassword: string) {
		const config = baseService.generateConfigData()
		config.auth = {
			username: pUsername,
			password: pPassword
		}
		return await Axios.post(`${import.meta.env.VITE_APP_API_URL}auth/login`, {}, config)
	},
	async authenticateTotp(token: string, code: string, mode: string) {
		const config = baseService.generateConfigData()
		const data = {
			code: code,
			token: token,
			mode: mode
		}
		return await Axios.post(`${import.meta.env.VITE_APP_API_URL}auth/login2fa`, data, config)
	},
	async requestTOTPCode(userID: string, code: string, enable_2fa_totp: boolean) {
		const config = baseService.generateConfigData()
		const data = {
			code: code,
			user_key: userID,
			enable_2fa: enable_2fa_totp
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/verify2fa_totp`,
			data,
			config
		)
	},
	async requestSMSCode(userID: string) {
		const config = baseService.generateConfigData()
		const data = {
			user_key: userID
		}
		return await Axios.post(`${import.meta.env.VITE_APP_API_URL}auth/verify2fa_sms`, data, config)
	},
	async requestEmailCode(userID: string) {
		const config = baseService.generateConfigData()
		const data = {
			user_key: userID
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/verify2fa_email`,
			data,
			config
		)
	},
	async forgotPassword(email: string) {
		const payload = {
			email: email
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/forgotpassword`,
			payload,
			baseService.generateConfigData()
		)
	},
	async resetPassword(token: string, password: string) {
		const payload = {
			token: token,
			new_password: password
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/forgotpassword/reset`,
			payload,
			baseService.generateConfigData()
		)
	},
	async getActivationQR(token: any) {
		const payload = {
			token: token
		}
		return await Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/activate/qr`,
			payload,
			baseService.generateConfigData()
		)
	},
	activate(
		activationToken: string,
		password: string,
		enable_2fa_sms: boolean,
		enable_2fa_email: boolean,
		enable_2fa_totp: boolean,
		totp_code: string
	) {
		return Axios.post(
			`${import.meta.env.VITE_APP_API_URL}datastorms/activate`,
			{
				activationToken,
				password,
				enable_2fa_sms,
				enable_2fa_email,
				enable_2fa_totp,
				code: totp_code
			},
			baseService.generateConfigData()
		)
	},
	update2fa(password: string, login_token: string, totp_enabled: boolean) {
		return Axios.post(
			`${import.meta.env.VITE_APP_API_URL}auth/update2fa`,
			{
				token: login_token,
				password,
				totp_enabled
			},
			baseService.generateConfigData()
		)
	}
}

export default userService
