import { createApp } from 'vue'
import App from './App.ts.vue'
import store from './store'
import router from './router'

import './assets/tailwind.css'
import 'vue-select/dist/vue-select.css'
import 'floating-vue/dist/style.css'
import 'v-calendar/dist/style.css'

import i18n from './i18n'
import * as Sentry from '@sentry/vue'
import vSelect from 'vue-select'
import VCalendar from 'v-calendar'
import VueNumberFormat from '@coders-tm/vue-number-format'
import FloatingVue from 'floating-vue'
import Shimmer from 'vue3-loading-shimmer'

import 'v-calendar/style.css'

const app = createApp(App)
	Sentry.init({
		app,
		dsn: "https://16c83ced39fd468f49950bb704eeadbf@o553525.ingest.us.sentry.io/4508414607228928",
		integrations: [
		  Sentry.browserTracingIntegration({ router }),
		  Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	  });

	  app.component('v-select', vSelect)
	  .component('shimmer', Shimmer)
	  .use(VCalendar, {
		  locales: {
			  'nl': {
				masks: {
				  dayPopover: 'WWW, D MMM YYYY',
				  // ...optional `title`, `weekdays`, `navMonths`, etc
				}
			  }
			}
	  })
	.use(FloatingVue)
	.use(VueNumberFormat, { prefix: '€ ', decimal: ',', thousand: '.' })
	.use(i18n)
	.use(router)
	.use(store)
	.mount('#app')