import { createStore } from 'vuex'
import { VuexPersistence } from 'vuex-persist'
import userServices from './user'

const vuexCookie = new VuexPersistence({
	storage: window.localStorage,
	modules: ['user']
})

export default createStore({
	state: {
		tempUser: {},
		umUser: {},
		portalData: {},
		accountRegistrationKey: '',
		passwordResetKey: '',
		woningData: [],
		percelenData: [],
		parceelColumns: [
			{
				name: 'parceelNumber',
				key: 'a',
				title: 'WTG-nr',
				classes: 'text-sm font-bold text-gray-800'
			},
			{
				name: 'turbine',
				key: 'b',
				title: 'Turbine',
				classes: 'text-sm text-gray-500'
			},
			{
				name: 'distance',
				key: 'c',
				title: 'Objecten',
				classes: 'text-sm text-gray-500'
			},
			{
				name: 'compensation',
				key: 'd',
				title: 'Grondrecht',
				type: 'price',
				classes: 'text-sm text-gray-500'
			},
			{
				name: 'compensationFirstYear',
				key: 'e',
				title: 'Vergoeding',
				type: 'price',
				classes: 'text-sm text-gray-500'
			}
		],
		woningenColumns: [
			{
				name: 'wtgNumber',
				key: 'f',
				title: 'WTG-nr',
				classes: 'text-sm font-bold text-gray-800'
			},
			{
				name: 'startDate',
				key: 'b',
				title: 'Start exploitatie',
				classes: 'text-sm text-gray-500'
			},
			{
				name: 'afstand',
				key: 'c',
				title: 'Afstand tot WTG',
				classes: 'text-sm text-gray-500'
			},
			{
				name: 'vergoedingPerWoningPerYear',
				key: 'd',
				title: 'Jaarlijkse vergoeding',
				type: 'price',
				classes: 'text-sm text-gray-500'
			},
			{
				name: 'total',
				key: 'e',
				title: 'Vergoeding 2022',
				type: 'price',
				classes: 'text-sm text-gray-500'
			}
		],
		columnParsers: {
			price: (parceel: string, column: string) => {
				let price
				if (parceel[column].includes('-')) {
					price = 0
				} else {
					price = parceel[column].replace(
						/€((\d{0,3})\.)*(\d{0,3})(,|\.)(\d{0,2})/g,
						(match: string, p1: string, p2: string, p3: string, p4: string, p5: string) => {
							return (p2 ? p2 : '') + (p3 ? p3 : '') + '.' + (p5 ? p5 : '')
						}
					)
				}
				return parseFloat(price)
			},
			date: (parceel: string, column: string) => {
				let date
				if (parceel[column] === '-') {
					date = 0
				} else {
					const dateArr: string[] = parceel[column].split('-')
					date = new Date(
						Number('20' + dateArr[2]),
						Number(dateArr[1]) - 1,
						Number(dateArr[0])
					).getTime()
				}
				return date
			}
		}
	},
	getters: {
		getWoningData: state => {
			return state.woningData
		},
		getPercelenData: state => {
			const data: any = []
			if (state.percelenData) {
				state.percelenData.forEach((element: any) => {
					if (element.jaar == new Date().getFullYear()) {
						data.push(element)
					}
				})
			}
			return data
		},
		getAllPercelenData: state => {
			return state.percelenData
		},
		getParceelColumns: state => {
			return state.parceelColumns
		},
		getParceelParsers: state => {
			return state.columnParsers
		},
		getAccountRegistrationKey: state => {
			return state.accountRegistrationKey
		},
		getPasswordResetKey: state => {
			return state.passwordResetKey
		},
		getPortalData: state => {
			return state.portalData
		}
	},
	mutations: {
		setAccountRegistrationKey: (state, key) => {
			state.accountRegistrationKey = key
		},
		setPasswordResetKey: (state, key) => {
			state.passwordResetKey = key
		},
		setWoningData: (state, data) => {
			state.woningData = data
		},
		setPercelenData: (state, data) => {
			state.percelenData = data
		},
		setTempUser: (state, user) => {
			state.tempUser = user
		},
		setPortalData: (state, data) => {
			state.portalData = data
		}
	},
	actions: {},
	modules: { user: userServices },
	plugins: [vuexCookie.plugin]
})
