<template>
	<div>
		<TransitionRoot as="template" :show="showErrorPrompt">
			<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :open="showErrorPrompt">
				<div
					class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="ease-in duration-200"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
						>&#8203;</span
					>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
						>
							<div>
								<div
									class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100"
								>
									<ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
								</div>
								<div class="mt-3 text-center sm:mt-5">
									<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
										{{ title }}
									</DialogTitle>
									<div class="mt-2">
										<p class="text-sm text-gray-500">
											{{ message }}
										</p>
									</div>
								</div>
							</div>
							<div class="mt-5 sm:mt-6">
								<button
									type="button"
									class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
									@click="showErrorPrompt = false"
								>
									{{ t('error.close') }}
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
	</div>
</template>
<script lang="ts">
import { useI18n } from 'vue-i18n'
import {
	Dialog,
	DialogOverlay,
	DialogTitle,
	TransitionChild,
	TransitionRoot
} from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		ExclamationTriangleIcon
	},
	setup() {
		const { t } = useI18n({
			inheritLocale: true,
			useScope: 'global'
		})
		return {
			t
		}
	},
	data() {
		return {
			showErrorPrompt: false,
			title: '',
			message: ''
		}
	},
	created() {
		this.title = this.t('error.title')
		this.message = this.t('error.message')
	},
	methods: {
		showDialog(title: string, message: string) {
			if (title) {
				this.title = title
			}
			if (message) {
				this.message = message
			}
			this.showErrorPrompt = true
		}
	}
}
</script>
