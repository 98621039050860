import { createI18n } from 'vue-i18n'
import enLocale from '@/i18n/en.json'
import nlLocale from '@/i18n/nl.json'

export default createI18n({
	legacy: false,
	locale: import.meta.env.VITE_APP_I18N_LOCALE || 'en',
	fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
	messages: {
		en: enLocale,
		nl: nlLocale
	}
})
