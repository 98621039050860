<template>
	<div>
		<TransitionRoot as="template" :show="showPrompt">
			<Dialog
				as="div"
				static
				class="fixed z-10 inset-0 overflow-y-auto"
				:open="showPrompt"
				@close="onClose"
			>
				<div
					class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
				>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="ease-in duration-200"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
						>&#8203;</span
					>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
						>
							<div>
								<div
									class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-sky-100"
								>
									<PencilSquareIcon class="h-6 w-6 text-primary" aria-hidden="true" />
								</div>
								<div class="mt-3 text-center sm:mt-5">
									<DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
										Tweefactorauthenticatiebeheer
									</DialogTitle>
									<DialogDescription as="h4" class="text-gray-700">
										Uw instellingen voor tweefactorauthenticatie beheren
									</DialogDescription>
									<PulseLoader
										v-if="loading"
										color="#009ee0"
										class="mt-8 mb-8 text-center w-full"
									></PulseLoader>

									<div v-if="!loading && !success" class="mt-4 text-left">
										<fieldset class="space-y-5">
											<div class="relative flex items-center">
												<div class="flex items-center h-5">
													<input
														id="comments"
														aria-describedby="comments-description"
														name="comments"
														type="checkbox"
														checked="True"
														disabled="True"
														class="h-4 w-4 text-gray-400 border-gray-300 rounded"
													/>
												</div>
												<div class="ml-3 text-sm">
													<label for="comments" class="font-medium text-gray-700">E-mail</label>
													<p id="comments-description" class="text-gray-500">
														Deze optie moet actief blijven.
													</p>
												</div>
											</div>
											<div class="relative flex items-center">
												<div class="flex items-center h-5">
													<input
														id="candidates"
														aria-describedby="candidates-description"
														name="candidates"
														type="checkbox"
														:checked="user.two_fa_totp_enabled"
														class="focus:ring-sky-500 h-4 w-4 text-primary border-gray-300 rounded"
														@change="twofactor_checked"
													/>
												</div>
												<div class="ml-3 text-sm">
													<label for="candidates" class="font-medium text-gray-700"
														>Tweefactorauthenticatie (2FA)-app
													</label>
													<p id="candidates-description" class="text-gray-500">
														Bijvoorbeeld Google Authenticator.
													</p>
												</div>
											</div>
										</fieldset>
									</div>

									<div
										v-if="success"
										class="mx-auto mt-6 flex items-center justify-center h-12 w-12 rounded-full"
									>
										<CheckIcon class="h-8 w-8 text-primary" aria-hidden="true" />
									</div>
									<p v-if="success">U instellingen zijn opgeslagen</p>

									<div
										v-if="changesMade && !loading && !success && totpCurrentlyEnabled"
										class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 mt-8 sm:pt-4"
									>
										<label
											for="about"
											class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
										>
											Huidige Wachtwoord
											<span class="text-red-600">*</span>
										</label>
										<div class="mt-1 sm:mt-0 sm:col-span-2">
											<input
												:value="currentPassword"
												type="password"
												:class="[
													passwordError ? 'border-red-600' : 'border-gray-300',
													'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
												]"
												@change="currentPassword_Changed"
											/>
										</div>
									</div>

									<div
										v-if="changesMade && !totpCurrentlyEnabled && !loading && !success"
										class="sm:border-t sm:border-gray-200 mt-8 sm:pt-4"
									>
										<div class="text-center">
											<img :src="qr_code" class="inline mb-4" />
										</div>
										<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
											<label
												for="about"
												class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
											>
												Code van 2FA-app
												<span class="text-red-600">*</span>
											</label>
											<div class="mt-1 sm:mt-0 sm:col-span-2">
												<input
													:value="tfaCode"
													type="password"
													:class="[
														tfaError ? 'border-red-600' : 'border-gray-300',
														'max-w-lg shadow-sm block w-full focus:ring-primary focus:border-sky-500 sm:text-sm border rounded-md'
													]"
													@change="tfaCode_Changed"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="!loading && !success" class="mt-5 sm:mt-8 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border shadow-sm px-4 py-2 mr-2 bg-white text-base font-medium hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:text-sm"
									@click="close"
								>
									Annuleren
								</button>
								<button
									type="button"
									:class="[
										'inline-flex justify-center flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 ml-2 bg-primary text-base font-medium text-white sm:text-sm',
										!changesMade
											? 'opacity-40 cursor-default'
											: 'cursor-pointer opacity-100 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary '
									]"
									:disabled="!changesMade"
									@click="save()"
								>
									Doorgaan
								</button>
							</div>
							<div v-if="success" class="mt-5 sm:mt-6 flex">
								<button
									type="button"
									class="inline-flex justify-center flex-1 rounded-md border border-transparent shadow-sm px-4 py-2 ml-2 bg-primary text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm"
									@click="close()"
								>
									Sluit
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
		<ErrorDialog ref="errorPrompt" />
	</div>
</template>
<script script="ts">
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
import {
	Dialog,
	DialogOverlay,
	DialogTitle,
	DialogDescription,
	TransitionChild,
	TransitionRoot
} from '@headlessui/vue'
import { PencilSquareIcon, CheckIcon } from '@heroicons/vue/24/outline'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import securityService from '@/services/securityService'
import ErrorDialog from './ErrorDialog.vue'

export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogDescription,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		ErrorDialog,
		PencilSquareIcon,
		PulseLoader,
		CheckIcon
	},
	props: {
		showPrompt: {
			type: Boolean,
			default: false
		}
	},
	emits: ['close'],
	setup() {
		//const selected
		const { t } = useI18n({
			inheritLocale: true,
			useScope: 'global'
		})
		return {
			t
		}
	},
	data() {
		return {
			loading: false,
			success: false,

			passwordError: false,
			tfaError: false,

			newValue: false,
			currentPassword: '',
			tfaCode: '',

			changesMade: false,
			errorMsg: ''
		}
	},
	computed: {
		...mapGetters({
			user: 'getCurrentUser'
		}),
		qr_code() {
			if (this.user.secret) {
				return this.user.secret.qr
			}
			return ''
		},
		totpCurrentlyEnabled() {
			return this.user.two_fa_totp_enabled
		}
	},
	created() {},
	methods: {
		save() {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			const parent = this
			if (!this.totpCurrentlyEnabled) {
				parent.loading = true
				this.tfaError = false
				if (!this.tfaCode) {
					this.tfaError = true
				}
				if (!this.tfaError) {
					const data = securityService.requestTOTPCode(this.user._key, this.tfaCode, true)
					Promise.resolve(data)
						.then(function (value) {
							parent.loading = false
							if (value.status === 200 && value) {
								parent.showDialog = true

								parent.passwordError = false
								parent.tfaError = false

								parent.newValue = false
								parent.currentPassword = ''
								parent.tfaCode = ''

								const user = parent.user
								user.two_fa_totp_enabled = true
								parent.$store.commit('setCurrentUser', user)

								parent.success = true
							} else if (value.status === 400 || value.status === 401) {
								parent.tfaError = true
							} else {
								parent.$refs.errorPrompt.showDialog()
							}
						}, null)
						.catch(function (value) {
							console.log(value)
							parent.loading = false
							parent.tfaError = true
						})
				}
			} else {
				this.passwordError = false
				if (!this.currentPassword) {
					this.passwordError = true
				}

				if (!this.passwordError) {
					this.loading = true

					const data = securityService.update2fa(
						this.currentPassword,
						this.user.accessToken,
						this.newValue
					)

					Promise.resolve(data)
						.then(function (value) {
							parent.loading = false
							if (value.status === 200 && value) {
								parent.showDialog = true
								if (value.data.qr_code) {
									parent.qr_code = value.data.qr_code
								} else {
									parent.success = true
									parent.passwordError = false
									parent.tfaError = false

									const user = parent.user
									user.two_fa_totp_enabled = false
									parent.$store.commit('setCurrentUser', user)

									parent.newValue = false
									parent.currentPassword = ''
									parent.tfaCode = ''
									this.$emit('close')
								}
							} else if (value.status === 400 || value.status === 401) {
								parent.passwordError = true
							} else {
								parent.$refs.errorPrompt.showDialog()
							}
						}, null)
						.catch(function (value) {
							console.log(value)
							parent.loading = false
							parent.passwordError = true
						})
				}
			}
		},
		onClose(e) {
			
		},
		close() {
			this.passwordError = false
			this.tfaError = false
			this.newValue = false
			this.currentPassword = ''
			this.tfaCode = ''
			this.success = false
			this.$emit('close')
		},
		twofactor_checked(e) {
			this.newValue = e.target.checked
			this.changesMade = !this.changesMade
		},
		currentPassword_Changed(e) {
			this.currentPassword = e.target.value
		},
		tfaCode_Changed(e) {
			this.tfaCode = e.target.value
		}
	}
}
</script>
